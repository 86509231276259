import React from 'react';

export const Add = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.6667 8.66659H8.66671V12.6666H7.33337V8.66659H3.33337V7.33325H7.33337V3.33325H8.66671V7.33325H12.6667V8.66659Z"
        fill="white"
      />
    </svg>
  );
};
