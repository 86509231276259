import * as React from 'react';
import { FiLogOut } from 'react-icons/fi';
import { useTimeout } from 'react-use';
import { signOut } from 'next-auth/react';
import {
  Box,
  Dropdown,
  Flex,
  MediaMatch,
  Text,
  useResponsiveBreakpoint,
} from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import type { User } from '~/shared/auth/useUser';
import { Avatar, ClientOnly } from '~/shared/components';

import { StyledArrow, StyledContext, StyledUserBox } from './styles';

type HeaderProps = { user: User };

export function UserDropdown({ user }: HeaderProps) {
  const { t } = useTranslate(['default']);

  // * This is necessary for force the component re-render in order to apply the correct button width
  useTimeout(500);
  useResponsiveBreakpoint();

  const userNames = user.name?.split(' ') ?? '';
  const simplifiedName = `${userNames.at(0)} ${userNames.at(-1)}`;

  const dropdownButtonRef = React.useRef<HTMLButtonElement>(null);
  const dropdownButtonWidth =
    dropdownButtonRef.current?.getBoundingClientRect().width;

  async function handleSignOut() {
    try {
      await fetch('/api/destroy-language-cookie');
    } finally {
      signOut({ redirect: false });
    }
  }

  return (
    <StyledUserBox>
      <Dropdown>
        <Dropdown.Trigger asChild>
          <StyledContext ref={dropdownButtonRef}>
            <Flex as="span" align="center" css={{ gap: '$3' }}>
              <ClientOnly
                placeholder={
                  <Box
                    css={{
                      width: 32,
                      height: 32,
                      bg: '#eceef0',
                      borderRadius: '$circle',
                    }}
                  />
                }
              >
                <Avatar name={user.name ?? ''} globalId={user.globalId ?? ''} />
              </ClientOnly>

              <MediaMatch greaterThan="md">
                <Text
                  css={{
                    display: 'block',
                    fontWeight: '$bold',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Text
                    as="span"
                    css={{ display: 'none', '@lg': { display: 'block' } }}
                  >
                    {user.name}
                  </Text>
                  <Text
                    as="span"
                    css={{ display: 'block', '@lg': { display: 'none' } }}
                  >
                    {simplifiedName}
                  </Text>
                </Text>
                {user.zone ? (
                  <Text css={{ color: '$gray575', fontSize: '$xs' }}>
                    {user.zone}
                  </Text>
                ) : null}
              </MediaMatch>
            </Flex>

            <StyledArrow />
          </StyledContext>
        </Dropdown.Trigger>
        <Dropdown.Content
          align="end"
          css={{
            mt: '-15px',
            pt: '15px',
            zIndex: '$overlay',
            borderTop: 'none',
            borderTopRightRadius: 0,
            borderColor: '$gray410',
            width: dropdownButtonWidth,
            position: 'relative',

            '@md': { minWidth: 'unset', borderTopLeftRadius: 0 },
            '@lg': { width: Math.min(dropdownButtonWidth ?? Infinity, 220) },

            '&::before': {
              content: '',
              height: '1px',
              bg: '$gray410',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '53%',

              '@md': {
                display: 'none',
              },
            },
          }}
        >
          <Dropdown.Item onSelect={handleSignOut} css={{ gap: '$sm', p: '$5' }}>
            <FiLogOut size={18} />
            <Text as="span" css={{ flex: 1 }}>
              {t('common.sign_out')}
            </Text>
          </Dropdown.Item>
        </Dropdown.Content>
      </Dropdown>
    </StyledUserBox>
  );
}
