import { globalCss } from '@ghq-abi/design-system';

export const globalStyles = globalCss({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  '@font-face': [
    {
      fontFamily: 'Satoshi',
      src: `url('/fonts/Satoshi-Light.woff2') format('woff2'),
      url('/fonts/Satoshi-Light.woff') format('woff')`,
      fontWeight: 300,
      fontDisplay: 'swap',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Satoshi',
      src: `url('/fonts/Satoshi-Regular.woff2') format('woff2'),
      url('/fonts/Satoshi-Regular.woff') format('woff')`,
      fontWeight: 400,
      fontDisplay: 'swap',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Satoshi',
      src: `url('/fonts/Satoshi-Medium.woff2') format('woff2'),
      url('/fonts/Satoshi-Medium.woff') format('woff')`,
      fontWeight: 500,
      fontDisplay: 'swap',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Satoshi',
      src: `url('/fonts/Satoshi-Bold.woff2') format('woff2'),
      url('/fonts/Satoshi-Bold.woff') format('woff')`,
      fontWeight: 700,
      fontDisplay: 'swap',
      fontStyle: 'normal',
    },
    {
      fontFamily: 'Satoshi',
      src: `url('/fonts/Satoshi-Black.woff2') format('woff2'),
      url('/fonts/Satoshi-Black.woff') format('woff')`,
      fontWeight: 900,
      fontDisplay: 'swap',
      fontStyle: 'normal',
    },
  ],

  '*, *::before, *::after': {
    margin: 0,
    padding: 0,
    'box-sizing': 'border-box',

    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
  },

  'html, body, #__next': {
    minHeight: '100vh',
  },

  body: {
    backgroundColor: '$gray100',
  },

  'body, input, button, textarea, select': {
    fontFamily: '$satoshi',
  },

  a: {
    textDecoration: 'none',
  },
});
