export type ApproveAndRejectProps = {
  size?: number;
  color?: string;
};

export function ApproveAndReject({
  size = 24,
  color = '$gray575',
}: ApproveAndRejectProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      color={color}
    >
      <path
        d="M8.59609 20.3032L6.5 18.2071L4.40391 20.3032C4.9882 20.7408 5.71383 21 6.5 21C7.28617 21 8.0118 20.7408 8.59609 20.3032ZM9.30319 19.5961C9.7408 19.0118 10 18.2862 10 17.5C10 16.7138 9.7408 15.9882 9.30319 15.4039L7.20711 17.5L9.30319 19.5961ZM3.69681 15.4039C3.2592 15.9882 3 16.7138 3 17.5C3 18.2862 3.2592 19.0118 3.69681 19.5961L5.79289 17.5L3.69681 15.4039ZM4.40391 14.6968L6.5 16.7929L8.59609 14.6968C8.0118 14.2592 7.28617 14 6.5 14C5.71383 14 4.9882 14.2592 4.40391 14.6968ZM6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 8.98528 8.98528 11 6.5 11ZM6.5 10C8.433 10 10 8.433 10 6.5C10 4.567 8.433 3 6.5 3C4.567 3 3 4.567 3 6.5C3 8.433 4.567 10 6.5 10ZM7.60957 5.18765C7.78207 4.97202 8.09672 4.93706 8.31235 5.10957C8.52798 5.28207 8.56294 5.59672 8.39043 5.81235L6.39043 8.31235C6.20427 8.54505 5.85717 8.56428 5.64645 8.35355L4.64645 7.35355C4.45118 7.15829 4.45118 6.84171 4.64645 6.64645C4.84171 6.45118 5.15829 6.45118 5.35355 6.64645L5.95854 7.25143L7.60957 5.18765ZM13.5 6C13.2239 6 13 5.77614 13 5.5C13 5.22386 13.2239 5 13.5 5H21.5C21.7761 5 22 5.22386 22 5.5C22 5.77614 21.7761 6 21.5 6H13.5ZM13.5 8C13.2239 8 13 7.77614 13 7.5C13 7.22386 13.2239 7 13.5 7H19.5C19.7761 7 20 7.22386 20 7.5C20 7.77614 19.7761 8 19.5 8H13.5ZM6.5 22C4.01472 22 2 19.9853 2 17.5C2 15.0147 4.01472 13 6.5 13C8.98528 13 11 15.0147 11 17.5C11 19.9853 8.98528 22 6.5 22ZM13.5 17C13.2239 17 13 16.7761 13 16.5C13 16.2239 13.2239 16 13.5 16H21.5C21.7761 16 22 16.2239 22 16.5C22 16.7761 21.7761 17 21.5 17H13.5ZM13.5 19C13.2239 19 13 18.7761 13 18.5C13 18.2239 13.2239 18 13.5 18H19.5C19.7761 18 20 18.2239 20 18.5C20 18.7761 19.7761 19 19.5 19H13.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
