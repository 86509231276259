import { styled } from '@ghq-abi/design-system';

export const StyledNav = styled('nav', {
  width: 'calc($2xs + $4)',
  px: '$3',
  pt: '$9',
  flex: 1,
  backgroundColor: '$white',
  overflow: 'auto',

  display: 'flex',
  flexDirection: 'column',
  gap: '0.375rem',

  variants: {
    collapsed: {
      true: {
        width: 'calc($5 + $20)',
        px: '$lg',
        overflow: 'hidden',
      },
    },
  },
});

export const StyledNavFooter = styled('footer', {
  mt: 'auto',
  mb: '0.625rem',

  display: 'flex',
  flexDirection: 'column',
  gap: '0.375rem',

  hr: {
    height: '$px',
    backgroundColor: '#ebeaed',
    border: 0,
    mx: '$md',
  },

  '> a:last-of-type': {
    mb: '0.375rem',
  },

  '> span': {
    alignSelf: 'center',
  },

  variants: {
    collapsed: {
      true: {
        hr: {
          mx: 0,
        },
      },
    },
  },
});
