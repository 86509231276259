import React from 'react';

export const ZoneDetails = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clip-path="url(#clip0_1007_5783)">
        <path
          d="M15.75 17.75C16.5784 17.75 17.25 17.0784 17.25 16.25C17.25 15.4216 16.5784 14.75 15.75 14.75C14.9216 14.75 14.25 15.4216 14.25 16.25C14.25 17.0784 14.9216 17.75 15.75 17.75Z"
          fill="#325A6D"
        />
        <path
          d="M5.25 7.25C6.07843 7.25 6.75 6.57843 6.75 5.75C6.75 4.92157 6.07843 4.25 5.25 4.25C4.42157 4.25 3.75 4.92157 3.75 5.75C3.75 6.57843 4.42157 7.25 5.25 7.25Z"
          fill="#325A6D"
        />
        <path
          d="M20.25 23.75C19.6567 23.75 19.0766 23.5741 18.5833 23.2444C18.0899 22.9148 17.7054 22.4462 17.4784 21.8981C17.2513 21.3499 17.1919 20.7467 17.3076 20.1647C17.4234 19.5828 17.7091 19.0482 18.1287 18.6287C18.5482 18.2091 19.0828 17.9234 19.6647 17.8076C20.2467 17.6919 20.8499 17.7513 21.3981 17.9784C21.9462 18.2054 22.4148 18.5899 22.7444 19.0833C23.0741 19.5766 23.25 20.1567 23.25 20.75C23.2477 21.5449 22.9308 22.3066 22.3687 22.8687C21.8066 23.4308 21.0449 23.7477 20.25 23.75ZM20.25 19.25C19.9533 19.25 19.6633 19.338 19.4166 19.5028C19.17 19.6676 18.9777 19.9019 18.8642 20.176C18.7507 20.4501 18.7209 20.7517 18.7788 21.0426C18.8367 21.3336 18.9796 21.6009 19.1893 21.8107C19.3991 22.0204 19.6664 22.1633 19.9574 22.2212C20.2483 22.2791 20.5499 22.2494 20.824 22.1358C21.0981 22.0223 21.3324 21.83 21.4972 21.5834C21.662 21.3367 21.75 21.0467 21.75 20.75C21.7488 20.3525 21.5904 19.9717 21.3094 19.6906C21.0283 19.4096 20.6475 19.2512 20.25 19.25Z"
          fill="#325A6D"
        />
        <path
          d="M22.5 12.5C22.4918 9.71775 21.383 7.05177 19.4156 5.0844C17.4482 3.11704 14.7823 2.00817 12 2.00002C10.2084 1.97445 8.44428 2.44143 6.9 3.35002L7.725 4.62502C8.31126 4.34324 8.91211 4.09289 9.525 3.87502C8.28517 6.32146 7.59409 9.00897 7.5 11.75H3C3.08403 10.5117 3.44271 9.30752 4.05 8.22502L2.925 7.25002C1.99178 8.84224 1.49989 10.6545 1.5 12.5C1.49983 13.8789 1.77131 15.2444 2.29892 16.5184C2.82654 17.7924 3.59996 18.95 4.57501 19.925C5.55005 20.9001 6.70763 21.6735 7.98163 22.2011C9.25562 22.7287 10.6211 23.0002 12 23C13.337 23.0126 14.663 22.7576 15.9 22.25L15.45 20.825C14.2204 21.3508 12.8848 21.582 11.55 21.5C9.95061 19.0401 9.06764 16.1834 9 13.25H22.425C22.487 13.0051 22.5123 12.7524 22.5 12.5ZM9.6 21.2C8.1086 20.753 6.7457 19.9558 5.625 18.875C4.13691 17.3523 3.21118 15.3686 3 13.25H7.5C7.6032 16.0227 8.32045 18.738 9.6 21.2ZM9 11.75C9.05966 8.82616 9.91534 5.97388 11.475 3.50002H12.525C14.0847 5.97388 14.9403 8.82616 15 11.75H9ZM16.5 11.75C16.4429 8.97062 15.723 6.24503 14.4 3.80002C16.1647 4.30864 17.7333 5.34104 18.8984 6.76062C20.0636 8.18019 20.7703 9.92005 20.925 11.75H16.5Z"
          fill="#325A6D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1007_5783">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
