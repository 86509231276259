import * as React from 'react';

type ModulesProps = {
  size?: number;
  color?: string;
};

export function Modules({ size = 24, color = '#ffffff' }: ModulesProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      alignmentBaseline="middle"
      fill="none"
      viewBox="0 0 22 22"
      color={color}
    >
      <path
        fill={color}
        d="M4.5 2.5a2 2 0 11-4 0 2 2 0 014 0zm6.5-2a2 2 0 100 4 2 2 0 000-4zm8.5 4a2 2 0 100-4 2 2 0 000 4zM2.5 9a2 2 0 100 4 2 2 0 000-4zM11 9a2 2 0 100 4 2 2 0 000-4zm8.5 0a2 2 0 100 4 2 2 0 000-4zm-17 8.5a2 2 0 100 4 2 2 0 000-4zm8.5 0a2 2 0 100 4 2 2 0 000-4zm8.5 0a2 2 0 100 4 2 2 0 000-4z"
      />
    </svg>
  );
}
