import React from 'react';

export const FrequencyDetails = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clip-path="url(#clip0_1007_5759)">
        <path
          d="M12 23C9.9233 23 7.89323 22.3842 6.16652 21.2304C4.4398 20.0767 3.09399 18.4368 2.29927 16.5182C1.50455 14.5996 1.29661 12.4884 1.70176 10.4516C2.1069 8.41476 3.10693 6.54383 4.57538 5.07538C6.04383 3.60693 7.91476 2.6069 9.95156 2.20176C11.9884 1.79661 14.0996 2.00455 16.0182 2.79927C17.9368 3.59399 19.5767 4.9398 20.7304 6.66652C21.8842 8.39323 22.5 10.4233 22.5 12.5C22.5 15.2848 21.3938 17.9555 19.4246 19.9246C17.4555 21.8938 14.7848 23 12 23ZM12 3.5C10.22 3.5 8.47992 4.02785 6.99987 5.01678C5.51983 6.00571 4.36628 7.41132 3.68509 9.05585C3.0039 10.7004 2.82567 12.51 3.17294 14.2558C3.5202 16.0016 4.37737 17.6053 5.63604 18.864C6.89472 20.1226 8.49836 20.9798 10.2442 21.3271C11.99 21.6743 13.7996 21.4961 15.4442 20.8149C17.0887 20.1337 18.4943 18.9802 19.4832 17.5001C20.4722 16.0201 21 14.28 21 12.5C21 10.1131 20.0518 7.82387 18.364 6.13604C16.6761 4.44822 14.387 3.5 12 3.5Z"
          fill="#325A6D"
        />
        <path
          d="M15.4425 17L11.25 12.8075V5.75H12.75V12.185L16.5 15.9425L15.4425 17Z"
          fill="#325A6D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1007_5759">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
