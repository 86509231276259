import { Paginate, styled, Text } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { useResponsive } from '~/shared/utils/media';

interface PaginationProps {
  onPageChange: (page: number) => void;
  countParticipants: number;
  pageCount: number;
}

const PaginateContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  gap: '$xs',
  height: '48px',
  bg: '$gray200',
  width: '100%',
  zIndex: '$docked',
});

const PaginateCountText = styled('p', {
  display: 'none',
  position: 'absolute',
  left: '$md',
  fontSize: '$xs',
  '@lg': { display: 'block' },
});

export function Pagination({
  countParticipants,
  pageCount,
  onPageChange,
}: PaginationProps) {
  const { isMobile } = useResponsive();
  const { t } = useTranslate('admin');

  return (
    <PaginateContainer>
      <PaginateCountText>
        {t('admin.my_scope.total_reports')}:{' '}
        <Text as="span" css={{ ml: '$xs', fontSize: '$xs' }}>
          {countParticipants}
        </Text>
      </PaginateCountText>

      <Paginate
        pageCount={pageCount}
        marginPagesDisplayed={isMobile ? 1 : 3}
        onPageChange={({ selected }) => onPageChange(selected)}
      />
    </PaginateContainer>
  );
}
