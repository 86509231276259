import * as React from 'react';
import type { Session } from 'next-auth';

import packageJson from '../../../package.json';
import { getEnv } from '../utils/environments';

export function useDatadog(locale: string, session?: Session) {
  React.useEffect(() => {
    async function init() {
      const { datadogRum } = await import('@datadog/browser-rum');

      datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID ?? '',
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? '',
        site: 'datadoghq.com',
        service: 'metrics',
        env: getEnv(),
        version: packageJson.version,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        enableExperimentalFeatures: ['clickmap'],
        defaultPrivacyLevel: 'allow',
      });

      datadogRum.startSessionReplayRecording();
    }

    async function trackUserSession(userSession: Session) {
      const { datadogRum } = await import('@datadog/browser-rum');

      datadogRum.setUser({
        email: userSession.user.email ?? '',
        id: userSession.user.employeeId ?? '',
        name: userSession.user.name ?? '',
      });
      datadogRum.setUserProperty('globalId', userSession.user.globalId);
      datadogRum.setUserProperty('isEligible', userSession.user.isEligible);
      datadogRum.setUserProperty('zone', userSession.user.zone);
      datadogRum.setUserProperty(
        'managedZones',
        JSON.stringify(userSession.user.managedZones)
      );
      datadogRum.setUserProperty(
        'roles',
        JSON.stringify(userSession.user.roles)
      );
      datadogRum.setUserProperty(
        'proxiedAs',
        JSON.stringify(userSession.user.proxiedAs)
      );
      datadogRum.setUserProperty('locale', locale);
      datadogRum.setUserProperty('session', JSON.stringify(userSession));
    }

    if (
      !window.DD_RUM &&
      typeof process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID === 'string' &&
      typeof process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN === 'string'
    ) {
      init();
    }

    if (session) {
      trackUserSession(session);
    }
  }, [locale, session]);
}
