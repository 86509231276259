import React from 'react';

export const NextArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <g clip-path="url(#clip0_1374_17417)">
        <path
          d="M12.5 6L11.4275 7.04475L15.6125 11.25H6.5V12.75H15.6125L11.4275 16.9298L12.5 18L18.5 12L12.5 6Z"
          fill="white"
        />
        <path
          d="M12.5 22.5C10.4233 22.5 8.39323 21.8842 6.66652 20.7304C4.9398 19.5767 3.59399 17.9368 2.79927 16.0182C2.00455 14.0996 1.79661 11.9884 2.20176 9.95156C2.6069 7.91476 3.60693 6.04383 5.07538 4.57538C6.54383 3.10693 8.41476 2.1069 10.4516 1.70176C12.4884 1.29661 14.5996 1.50455 16.5182 2.29927C18.4368 3.09399 20.0767 4.4398 21.2304 6.16652C22.3842 7.89323 23 9.9233 23 12C22.9969 14.7838 21.8896 17.4527 19.9212 19.4212C17.9527 21.3896 15.2838 22.4969 12.5 22.5ZM12.5 3C10.72 3 8.97992 3.52785 7.49987 4.51678C6.01983 5.50571 4.86628 6.91132 4.18509 8.55585C3.5039 10.2004 3.32567 12.01 3.67294 13.7558C4.0202 15.5016 4.87737 17.1053 6.13604 18.364C7.39472 19.6226 8.99836 20.4798 10.7442 20.8271C12.49 21.1743 14.2996 20.9961 15.9442 20.3149C17.5887 19.6337 18.9943 18.4802 19.9832 17.0001C20.9722 15.5201 21.5 13.78 21.5 12C21.4973 9.61389 20.5482 7.32629 18.861 5.63905C17.1737 3.95181 14.8861 3.00272 12.5 3Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1374_17417">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
