import { styled } from '@ghq-abi/design-system';

export const StyledHeader = styled('header', {
  height: '$20',
  width: 'calc($full - var(--removed-body-scroll-bar-size, 0px))',
  backgroundColor: '$white',
  borderBottom: '1px solid $gray410',
  px: '$xl',
  zIndex: '$sticky',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$md',

  position: 'fixed',
  top: 0,
  left: 0,
});
