import { useState } from 'react';

export function useDisclosure() {
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    setIsOpen(true);
  };

  const closeDialog = () => {
    setIsOpen(false);
  };

  const toggleDialog = () => {
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  return {
    isOpen,
    openDialog,
    closeDialog,
    toggleDialog,
  };
}
