import * as React from 'react';

type BadgeProps = {
  size?: number;
  color?: string;
};

export function Badge({ size = 24, color = '#ffffff' }: BadgeProps) {
  const id = React.useId();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      color={color}
    >
      <g fill="currentColor" clipPath={`url(#${id})`}>
        <path d="M12 14.667a2.82 2.82 0 100-5.64 2.82 2.82 0 000 5.64zm0-4.573a1.754 1.754 0 110 3.507 1.754 1.754 0 010-3.508zM14.667 2.667a1.333 1.333 0 00-1.333-1.333h-2.667a1.333 1.333 0 00-1.333 1.333v4.667h5.333V2.667zM13.334 6h-2.667V2.667h2.667V6zM17.333 20v-1.533a.747.747 0 00-.173-.487A6.602 6.602 0 0012 15.793 6.6 6.6 0 006.84 18a.753.753 0 00-.173.487V20h1.066v-1.42A5.553 5.553 0 0112 16.86a5.553 5.553 0 014.267 1.727V20h1.066z" />
        <path d="M18.667 4H16v1.333h2.667v16H5.333v-16H8V4H5.333A1.333 1.333 0 004 5.333v16a1.333 1.333 0 001.333 1.334h13.334A1.333 1.333 0 0020 21.333v-16A1.334 1.334 0 0018.667 4z" />
      </g>
      <defs>
        <clipPath id={id}>
          <path fill="transparent" d="M0 0H24V24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
