interface SurveyCreateMenuProps {
  size?: number;
  color?: string;
}

export function SurveyListMenu({
  size = 24,
  color = '#fff',
}: SurveyCreateMenuProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 17 22"
      fill="none"
    >
      <path
        d="M3.75 12.7578H9.75V14.2578H3.75V12.7578ZM3.75 9.00781H12.75V10.5078H3.75V9.00781ZM3.75 16.5078H7.5V18.0078H3.75V16.5078Z"
        fill={color}
        fillOpacity="0.6"
      />
      <path
        d="M15 3.00781H12.75V2.25781C12.75 1.85999 12.592 1.47846 12.3107 1.19715C12.0294 0.915848 11.6478 0.757813 11.25 0.757812H5.25C4.85218 0.757813 4.47064 0.915848 4.18934 1.19715C3.90804 1.47846 3.75 1.85999 3.75 2.25781V3.00781H1.5C1.10218 3.00781 0.720644 3.16585 0.43934 3.44715C0.158035 3.72846 0 4.10999 0 4.50781V20.2578C0 20.6556 0.158035 21.0372 0.43934 21.3185C0.720644 21.5998 1.10218 21.7578 1.5 21.7578H15C15.3978 21.7578 15.7794 21.5998 16.0607 21.3185C16.342 21.0372 16.5 20.6556 16.5 20.2578V4.50781C16.5 4.10999 16.342 3.72846 16.0607 3.44715C15.7794 3.16585 15.3978 3.00781 15 3.00781ZM5.25 2.25781H11.25V5.25781H5.25V2.25781ZM15 20.2578H1.5V4.50781H3.75V6.75781H12.75V4.50781H15V20.2578Z"
        fill={color}
        fillOpacity="0.6"
      />
    </svg>
  );
}
