type NominationsProps = {
  size?: number;
  color?: string;
};

export function Nominations({ color = '#fff', size = 24 }: NominationsProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      color={color}
    >
      <g clipPath="url(#clip0_16240_3652)">
        <path
          d="M9 11C9.79113 11 10.5645 10.7654 11.2223 10.3259C11.8801 9.88635 12.3928 9.26164 12.6955 8.53074C12.9983 7.79983 13.0775 6.99556 12.9231 6.21964C12.7688 5.44372 12.3878 4.73098 11.8284 4.17157C11.269 3.61216 10.5563 3.2312 9.78036 3.07686C9.00444 2.92252 8.20017 3.00173 7.46927 3.30448C6.73836 3.60723 6.11365 4.11992 5.67412 4.77772C5.2346 5.43552 5 6.20888 5 7C5 8.06087 5.42143 9.07828 6.17157 9.82843C6.92172 10.5786 7.93913 11 9 11ZM9 5C9.39556 5 9.78224 5.1173 10.1111 5.33706C10.44 5.55683 10.6964 5.86918 10.8478 6.23463C10.9991 6.60009 11.0387 7.00222 10.9616 7.39018C10.8844 7.77814 10.6939 8.13451 10.4142 8.41422C10.1345 8.69392 9.77814 8.8844 9.39018 8.96157C9.00222 9.03874 8.60009 8.99914 8.23463 8.84776C7.86918 8.69639 7.55682 8.44004 7.33706 8.11114C7.1173 7.78224 7 7.39556 7 7C7 6.46957 7.21071 5.96086 7.58579 5.58579C7.96086 5.21072 8.46957 5 9 5Z"
          fill="currentColor"
        />
        <path
          d="M17 13C17.5933 13 18.1734 12.8241 18.6667 12.4944C19.1601 12.1648 19.5446 11.6962 19.7716 11.1481C19.9987 10.5999 20.0581 9.99667 19.9424 9.41473C19.8266 8.83279 19.5409 8.29824 19.1213 7.87868C18.7018 7.45912 18.1672 7.1734 17.5853 7.05765C17.0033 6.94189 16.4001 7.0013 15.8519 7.22836C15.3038 7.45543 14.8352 7.83994 14.5056 8.33329C14.1759 8.82664 14 9.40666 14 10C14 10.7957 14.3161 11.5587 14.8787 12.1213C15.4413 12.6839 16.2044 13 17 13ZM17 9C17.1978 9 17.3911 9.05865 17.5556 9.16853C17.72 9.27841 17.8482 9.43459 17.9239 9.61732C17.9996 9.80004 18.0194 10.0011 17.9808 10.1951C17.9422 10.3891 17.847 10.5673 17.7071 10.7071C17.5673 10.847 17.3891 10.9422 17.1951 10.9808C17.0011 11.0194 16.8 10.9996 16.6173 10.9239C16.4346 10.8482 16.2784 10.72 16.1685 10.5556C16.0586 10.3911 16 10.1978 16 10C16 9.73478 16.1054 9.48043 16.2929 9.29289C16.4804 9.10536 16.7348 9 17 9Z"
          fill="currentColor"
        />
        <path
          d="M17 14.0001C15.8918 14.0013 14.8155 14.3706 13.94 15.0501C12.9605 14.0744 11.7141 13.4106 10.3578 13.1425C9.00159 12.8743 7.59632 13.0137 6.31923 13.5432C5.04213 14.0726 3.95041 14.9684 3.18174 16.1175C2.41307 17.2666 2.00187 18.6176 2 20.0001C2 20.2653 2.10536 20.5196 2.29289 20.7072C2.48043 20.8947 2.73478 21.0001 3 21.0001C3.26522 21.0001 3.51957 20.8947 3.70711 20.7072C3.89464 20.5196 4 20.2653 4 20.0001C4 18.674 4.52678 17.4022 5.46447 16.4645C6.40215 15.5268 7.67392 15.0001 9 15.0001C10.3261 15.0001 11.5979 15.5268 12.5355 16.4645C13.4732 17.4022 14 18.674 14 20.0001C14 20.2653 14.1054 20.5196 14.2929 20.7072C14.4804 20.8947 14.7348 21.0001 15 21.0001C15.2652 21.0001 15.5196 20.8947 15.7071 20.7072C15.8946 20.5196 16 20.2653 16 20.0001C16.0024 18.8285 15.7064 17.6756 15.14 16.6501C15.5819 16.3008 16.1132 16.0831 16.6731 16.0217C17.233 15.9604 17.7989 16.0578 18.306 16.303C18.813 16.5482 19.2408 16.9312 19.5404 17.4082C19.84 17.8851 19.9993 18.4368 20 19.0001C20 19.2653 20.1054 19.5196 20.2929 19.7072C20.4804 19.8947 20.7348 20.0001 21 20.0001C21.2652 20.0001 21.5196 19.8947 21.7071 19.7072C21.8946 19.5196 22 19.2653 22 19.0001C22 17.674 21.4732 16.4022 20.5355 15.4645C19.5979 14.5268 18.3261 14.0001 17 14.0001Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_16240_3652">
          <rect width={size} height={size} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
