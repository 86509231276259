type DownloadDocumentsProps = {
  size?: number;
  color?: string;
};

export function DownloadDocuments({
  size = 24,
  color = '#000',
}: DownloadDocumentsProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
      color={color}
    >
      <path
        fill="currentColor"
        d="M12.1816 15.9149H3.84715V14.824H12.1816V15.9149ZM12.1816 11.7586H3.84715V10.6677H12.1816V11.7586ZM12.1816 20.0822H3.84715V18.9913H12.1816V20.0822ZM0.181641 4.41676H12.4075L15.8471 7.85638V24.2495H0.181641V4.41676ZM11.9558 5.50764H1.27256V23.1585H14.7562V8.308L11.9558 5.50764ZM20.0144 20.0822H15.3017V18.9913H18.9235V4.14072L16.1231 1.3404H5.43984V4.9622H4.34895V0.249481H16.5748L20.0144 3.68912L20.0144 20.0822Z"
      />
    </svg>
  );
}
