import { Text } from '@ghq-abi/design-system';

type PositionAndZoneProps = {
  zoneAcronym?: string;
  position?: string;
};
export function PositionAndZone({
  position,
  zoneAcronym,
}: PositionAndZoneProps) {
  return (
    <Text
      css={{
        display: 'block',
        fontSize: '$xs',
        color: '$gray575',
        fontWeight: '$normal',
      }}
    >
      {[position, zoneAcronym].filter(Boolean).join(' - ')}
    </Text>
  );
}
