import React from 'react';
import { Box, Flex, Label } from '@ghq-abi/design-system';

interface FilterModuleOptionProps {
  color: string;
  label: string;
  active: boolean;
  onClick: () => void;
  total: number;
}

export const FilterModuleOption = ({
  color,
  label,
  active,
  onClick,
  total,
}: FilterModuleOptionProps) => {
  return (
    <Flex
      align="center"
      onClick={onClick}
      css={{
        gap: '$1',
        padding: '6px 8px',
        borderRadius: '$1',
        backgroundColor: '#fff',
        minWidth: 128,
        cursor: 'pointer',
        filter: `brightness(${active ? '95%' : '100%'})`,
        '&:hover': {
          filter: `brightness(95%)`,
        },
      }}
    >
      <Box
        css={{
          backgroundColor: color,
          width: '9px',
          height: '9px',
        }}
      />
      <Label
        css={{
          color: ' var(--Fill-Dark-Main-Dark, #2E2C34)',
          fontWeight: 600,
        }}
      >
        {total}
      </Label>
      <Label
        css={{
          color: 'var(--Fill-Dark-Dark-2, #84818A)',
          fontWeight: 600,
        }}
      >
        {label}
      </Label>
    </Flex>
  );
};
