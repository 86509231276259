import React from 'react';

export const Module = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clip-path="url(#clip0_1374_16830)">
        <path
          d="M21 12.5V17H3V5H8.25V3.5H3C2.60218 3.5 2.22064 3.65804 1.93934 3.93934C1.65804 4.22064 1.5 4.60218 1.5 5V17C1.5 17.3978 1.65804 17.7794 1.93934 18.0607C2.22064 18.342 2.60218 18.5 3 18.5H9V21.5H6V23H18V21.5H15V18.5H21C21.3978 18.5 21.7794 18.342 22.0607 18.0607C22.342 17.7794 22.5 17.3978 22.5 17V12.5H21ZM13.5 21.5H10.5V18.5H13.5V21.5Z"
          fill="#325A6D"
        />
        <path
          d="M13.5 14.0001H13.4925C13.3309 13.9984 13.1741 13.9445 13.0455 13.8465C12.917 13.7485 12.8236 13.6115 12.7792 13.4561L11.4342 8.75008H8.25V7.25008H12C12.163 7.25008 12.3215 7.30316 12.4516 7.4013C12.5817 7.49944 12.6763 7.63728 12.7211 7.79398L13.5268 10.6132L15.7834 3.27943C15.832 3.12706 15.9274 2.99389 16.0561 2.89886C16.1847 2.80383 16.3401 2.75177 16.5 2.75008C16.658 2.74774 16.8125 2.79637 16.9406 2.88874C17.0688 2.98112 17.1638 3.11233 17.2115 3.26293L18.5405 7.25008H22.5V8.75008H18C17.8426 8.75008 17.6892 8.70055 17.5615 8.60851C17.4338 8.51646 17.3383 8.38657 17.2885 8.23723L16.5286 5.95723L14.2167 13.4707C14.1695 13.624 14.0745 13.7581 13.9455 13.8534C13.8165 13.9486 13.6604 14.0001 13.5 14.0001Z"
          fill="#325A6D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1374_16830">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
