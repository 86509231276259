// ! All custom icons should have color and size props
export * from './Add';
export * from './Admin';
export * from './ApproveAndReject';
export * from './Approved';
export * from './ArrowCurved';
export * from './Badge';
export * from './BandsDetails';
export * from './BottomThird';
export * from './CalendarFrequency';
export * from './Chart';
export * from './ChatQuestion';
export * from './Chevron';
export * from './ChevronDown';
export * from './CircleCheck';
export * from './CircularArrowNext';
export * from './CommentError';
export * from './Copy';
export * from './Decline';
export * from './Document';
export * from './Download';
export * from './DownloadDocuments';
export * from './DownloadPdf';
export * from './Edit';
export * from './EditBox';
export * from './Ellipses';
export * from './Eraser';
export * from './Excel';
export * from './Feedback';
export * from './Filter';
export * from './FrequencyDetails';
export * from './Gear';
export * from './Home';
export * from './ManagementDetails';
export * from './MediumThird';
export * from './MetricsDetails';
export * from './MetricsType';
export * from './Module';
export * from './ModuleDetails';
export * from './Modules';
export * from './NextArrow';
export * from './Nominations';
export * from './NotEnoughInfo';
export * from './Pdf';
export * from './Pending';
export * from './PersonFeedBack';
export * from './PopulationDetails';
export * from './Profile';
export * from './Proxy';
export * from './QuestionDetails';
export * from './Refresh';
export * from './Report';
export * from './Reports';
export * from './Save';
export * from './SkipComment';
export * from './SortBoolAsc';
export * from './SortBoolDesc';
export * from './StartProxy';
export * from './StopMeeting';
export * from './StopProxy';
export * from './SubmitDocument';
export * from './Survey';
export * from './SurveyCreateMenu';
export * from './SurveyListMenu';
export * from './Target';
export * from './TopThird';
export * from './Trash';
export * from './Trigger';
export * from './Undo';
export * from './UnlockEdit';
export * from './UserCheck';
export * from './Vote';
export * from './Workday';
export * from './Xls';
export * from './ZoneDetails';
