import React from 'react';

export const PopulationDetails = () => {
  return (
    <svg
      width="26"
      height="28"
      viewBox="0 0 26 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1606_8081)">
        <path
          d="M5.5 1.82031H4.25V18.0703C4.25 18.4018 4.3817 18.7198 4.61612 18.9542C4.85054 19.1886 5.16848 19.3203 5.5 19.3203H21.75V18.0703H5.5V1.82031Z"
          fill="#325A6D"
        />
        <path
          d="M21.75 6.19531H17.375V7.44531H19.6188L14.875 12.1891L12.1938 9.50156C12.1356 9.44298 12.0665 9.39649 11.9904 9.36476C11.9142 9.33303 11.8325 9.31669 11.75 9.31669C11.6675 9.31669 11.5858 9.33303 11.5096 9.36476C11.4335 9.39649 11.3644 9.44298 11.3062 9.50156L6.75 14.0641L7.63125 14.9453L11.75 10.8266L14.4313 13.5141C14.4894 13.5726 14.5585 13.6191 14.6346 13.6509C14.7108 13.6826 14.7925 13.6989 14.875 13.6989C14.9575 13.6989 15.0392 13.6826 15.1154 13.6509C15.1915 13.6191 15.2606 13.5726 15.3187 13.5141L20.5 8.32656V10.5703H21.75V6.19531Z"
          fill="#325A6D"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1606_8081"
          x="-1"
          y="0"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1606_8081"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1606_8081"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
