import { styled } from '@ghq-abi/design-system';

export const StyledActionWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$3-5',

  variants: {
    isDetached: {
      true: {
        // $20 is the header height
        height: '$20',
        pl: '$xl',
      },
    },
  },
});

export const StyledHamburger = styled('button', {
  appearance: 'none',
  border: 0,
  backgroundColor: 'transparent',
  display: 'flex',
  fontSize: '$lg',
  cursor: 'pointer',
  color: '$gray575',
  transition: '$quickly-ease',
  borderRadius: '$circle',
  p: '$sm',

  '&:active': {
    boxShadow: '0 0 0 $space$sm $colors$gray300',
    backgroundColor: '$gray300',
  },

  '&:focus': {
    backgroundColor: '$gray300',
    boxShadow: '0 0 0 3px $colors$gray700',
    outline: 'none',
  },
});
