import { styled } from '@ghq-abi/design-system';

import { StyledNav } from './components/NavContent/styles';

export const StyledWrapper = styled('div', {
  position: 'fixed',
  // $20 is the header height
  top: '$20',
  left: 0,
  bottom: 0,
  overflowX: 'hidden',

  display: 'flex',
  flexDirection: 'column',
  zIndex: '$sticky',

  [StyledNav.toString()]: {
    display: 'none',
  },

  '@lg': {
    [StyledNav.toString()]: {
      display: 'inherit',
    },
  },
});
