import * as React from 'react';
import { CiWarning } from 'react-icons/ci';
import { CSS, theme, Tooltip } from '@ghq-abi/design-system';

import { useClickableTooltip } from '~/shared/hooks';

import { ClickableTooltipTrigger } from '../ClickableTooltipTrigger';

type QuestionTooltipProps = Omit<
  React.ComponentProps<typeof Tooltip>,
  'children'
> & {
  iconSize?: number;
  buttonCss?: CSS;
};

export function WarningTooltip({
  iconSize = 25,
  buttonCss,
  ...props
}: QuestionTooltipProps) {
  const { rootTooltipProps, openTooltip } = useClickableTooltip();

  return (
    <Tooltip showArrow alignOffset={-iconSize} {...props} {...rootTooltipProps}>
      <ClickableTooltipTrigger
        onClick={openTooltip}
        onTouchEnd={e => e.stopPropagation()}
        css={{
          ...buttonCss,
        }}
      >
        <CiWarning size={iconSize} color={theme.colors.red300.value} />
      </ClickableTooltipTrigger>
    </Tooltip>
  );
}
