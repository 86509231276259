import { Button, Dialog, Flex, reusables } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { CircleCheck } from '../icons/CircleCheck';

interface SuccessDialogProps {
  title: string;
  description: string;
  buttonText: string;
  isOpen?: boolean;
  isLoading?: boolean;
  onClose: () => void;
}

export function SuccessDialog({
  description,
  onClose,
  title,
  isOpen,
  buttonText,
  isLoading,
}: SuccessDialogProps) {
  const { t } = useTranslate('default');

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <Dialog.Content
        css={{
          overflow: 'auto',
          maxWidth: 'calc($lg - $7)',
        }}
        className={reusables.scrollbar()}
      >
        <Dialog.Close asChild>
          <Dialog.CloseIcon
            aria-label={t('common.close_dialog')}
            disabled={isLoading}
          />
        </Dialog.Close>

        <Dialog.Title
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            color: '$gray780',
            fontWeight: '$bold',
            gap: '$lg',
          }}
        >
          <CircleCheck size={40} />

          {title}
        </Dialog.Title>

        <Dialog.Description
          css={{
            textAlign: 'center',
            fontWeight: '$medium',
            mt: '$md',
            mb: '$lg',
            color: '$gray780',
          }}
        >
          {description}
        </Dialog.Description>

        <Flex justify="center">
          <Dialog.Close asChild>
            <Button
              loading={isLoading}
              disabled={isLoading}
              onClick={onClose}
              css={{ width: '$44' }}
            >
              {buttonText}
            </Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog>
  );
}
