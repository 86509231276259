import { css, styled } from '@ghq-abi/design-system';

export const linkStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: '$md',

  px: 26,
  py: 14,
  textDecoration: 'none',
  fontSize: '$sm',
  fontWeight: '$bold',
  color: '#2e2c34',
  borderRadius: '$2',
  transition: 'background-color 100ms linear',

  svg: {
    flexShrink: 0,
  },

  'svg:not(.chevron-icon)': {
    fontSize: '$2xl',
    color: '$gray575',
  },

  '&:hover, &:focus': {
    backgroundColor: '$gray400',
  },

  variants: {
    collapsed: {
      true: {
        p: '$2-5',

        'svg:not(.chevron-icon)': {
          color: 'rgba(0, 0, 0, 0.6)',
          mx: 'auto',
        },
      },
    },

    active: {
      true: {
        color: '$white',
        backgroundColor: '$black',

        'svg:not(.chevron-icon)': {
          color: '$white',
        },

        '&:hover, &:focus': {
          backgroundColor: '$gray700',
        },
      },
    },
  },

  compoundVariants: [
    {
      collapsed: true,
      active: true,
      css: {
        'svg:not(.chevron-icon)': {
          color: '$white',
        },
      },
    },
  ],
});

export const StyledLink = styled('a', linkStyles);
