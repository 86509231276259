import type { Session } from 'next-auth';
import {
  AbilityBuilder,
  AbilityClass,
  ConditionsMatcher,
  mongoQueryMatcher,
  PureAbility,
} from '@casl/ability';

enum Action {
  Create = 'create',
  View = 'view',
  List = 'list',
  Manage = 'manage',
  Vote = 'vote',
  Facilitate = 'facilitate',
  Proxy = 'proxy',
  Unlock = 'unlock',
  Skip = 'skip',
  Delete = 'delete',
}

export type Entities = 'Home' | 'Surveys';

export type AbilityTuple = [`${Action}`, Entities | Record<string, unknown>];

export type AppAbility = PureAbility<AbilityTuple>;

export function createUserAbility(
  _?: Session['user'],
  rules?: AppAbility['_indexedRules']
) {
  const { can, build } = new AbilityBuilder(
    PureAbility as AbilityClass<AppAbility>
  );
  can(Action.View, 'Home');
  can(Action.View, 'Surveys');
  const ability = build({
    conditionsMatcher: mongoQueryMatcher as ConditionsMatcher<unknown>,
  });

  if (rules) {
    ability.update(rules);
  }

  return ability;
}
