import { Box, CSS, Flex, styled } from '@ghq-abi/design-system';

export const StyledWrapper = styled(Flex, {
  // $20 is the header height
  pt: '$20',

  '@lg': {
    pt: '$10',
  },

  minHeight: '100vh',

  variants: {
    fullscreen: { true: { pt: 0 } },
  },
});

const fullscreenStyles: CSS = {
  p: 0,
};

export const StyledContent = styled(Box, {
  flex: 1,
  maxWidth: '$full',
  pb: '$md',
  px: '$xs',

  display: 'flex',
  flexDirection: 'column',

  '@lg': {
    // $sizes$2xs + $4 is the nav width
    pl: 'calc($sizes$2xs + $4 + $xs)',
    pt: 'calc($9 + $md)',
  },

  variants: {
    navCollapsed: {
      true: {
        pl: '$sm',
        pr: '$sm',

        '@lg': {
          // $24 is the nav width
          pl: 'calc($24 + $sm)',
        },
      },
    },

    fullscreen: {
      true: fullscreenStyles,
    },
  },

  compoundVariants: [
    {
      navCollapsed: true,
      fullscreen: true,
      css: fullscreenStyles,
    },
  ],
});
