import * as React from 'react';
import { RiQuestionLine } from 'react-icons/ri';
import { CSS, theme, Tooltip } from '@ghq-abi/design-system';

import { useClickableTooltip } from '~/shared/hooks';

import { ClickableTooltipTrigger } from '../ClickableTooltipTrigger';

type QuestionTooltipProps = Omit<
  React.ComponentProps<typeof Tooltip>,
  'children'
> & {
  iconSize?: number;
  buttonCss?: CSS;
};

export function QuestionTooltip({
  iconSize = 18,
  buttonCss,
  ...props
}: QuestionTooltipProps) {
  const { rootTooltipProps, openTooltip } = useClickableTooltip();

  return (
    <Tooltip showArrow alignOffset={-iconSize} {...props} {...rootTooltipProps}>
      <ClickableTooltipTrigger
        onClick={openTooltip}
        onTouchEnd={e => e.stopPropagation()}
        css={{
          ...buttonCss,
        }}
      >
        <RiQuestionLine size={iconSize} color={theme.colors.blue350.value} />
      </ClickableTooltipTrigger>
    </Tooltip>
  );
}
