import { OverlayContainer } from './styles';

interface OverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  isActive?: boolean;
  onClick?: () => void;
}

export function Overlay({ isActive = true, onClick = () => {} }: OverlayProps) {
  if (!isActive) return null;

  return (
    <OverlayContainer
      aria-hidden
      onClick={event => {
        onClick();
        event.stopPropagation();
      }}
      onTouchStart={event => {
        onClick();
        event.stopPropagation();
      }}
    />
  );
}
