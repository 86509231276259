import * as React from 'react';

import { useNav } from '~/app/contexts/NavContext';

import { NavContent } from '../NavContent';

import { StyledWrapper } from './styles';

export function NavDrawer() {
  const { isAsideOpen, openCollapse, toggleCollapse, closeAside } = useNav();

  const menuButtonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        closeAside();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeAside]);

  React.useEffect(() => {
    if (isAsideOpen) {
      const TRANSITION_SLIDE_TIME = 250;

      setTimeout(() => {
        menuButtonRef.current?.focus();
      }, TRANSITION_SLIDE_TIME);
    }
  }, [isAsideOpen]);

  return (
    <StyledWrapper open={isAsideOpen} aria-hidden={!isAsideOpen}>
      <NavContent
        shouldShowHeader
        ref={menuButtonRef}
        onOpenRequest={openCollapse}
        onToggleRequest={toggleCollapse}
      />
    </StyledWrapper>
  );
}
