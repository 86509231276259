import * as React from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, CSS } from '@ghq-abi/design-system';

import { StyledLink } from './styles';

type NavLinkProps = {
  icon: React.ReactNode;
  title: string;
  href: string;
  titleCss?: CSS;
} & React.ComponentProps<typeof StyledLink>;

export function NavLink({
  href,
  icon,
  title,
  titleCss,
  ...props
}: NavLinkProps) {
  const { route } = useRouter();

  return (
    <Link href={href} passHref legacyBehavior>
      <StyledLink
        active={route === href}
        title={props.collapsed ? title : undefined}
        {...props}
      >
        {icon}
        {!props.collapsed && (
          <Box as="span" css={titleCss}>
            {title}
          </Box>
        )}
      </StyledLink>
    </Link>
  );
}
