// import { Flex } from '@ghq-abi/design-system';

import { useNav } from '~/app/contexts/NavContext';
import { useUser } from '~/shared/auth/useUser';

import { HeaderActions } from '../HeaderActions';
// import { ModulesDropdown } from '../ModulesDropdown';
import { UserDropdown } from '../UserDropdown';

import { StyledHeader } from './styles';

export function Header() {
  const user = useUser();

  const { isCollapsed, toggleCollapse } = useNav();

  return (
    <StyledHeader>
      <HeaderActions
        isCollapsed={isCollapsed}
        onToggleRequest={toggleCollapse}
      />
      {/* <Flex gap="md" align="center">
        <ModulesDropdown /> */}
      {user && <UserDropdown user={user} />}
      {/* </Flex> */}
    </StyledHeader>
  );
}
