type SurveyProps = {
  size?: number;
  color?: string;
};

export function Survey({ size = 20, color = 'white' }: SurveyProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 20"
      color={color}
    >
      <g clipPath="url(#clip0_17619_2403)">
        <path
          d="M16.25 18.7502H3.75V3.75016H5V5.00016H15V3.75016H16.25L17.5 2.50141L15 2.50016V1.25016H12.1537C11.9369 0.870319 11.6235 0.554583 11.2452 0.334974C10.867 0.115365 10.4374 -0.000305176 10 -0.000305176C9.56262 -0.000305176 9.13303 0.115365 8.75478 0.334974C8.37653 0.554583 8.06309 0.870319 7.84625 1.25016H5V2.50016H2.5V20.0002H17.5V9.87391L16.25 11.1239V18.7502ZM6.25 2.50016H8.75V2.10266C8.75 1.99071 8.77205 1.87985 8.81489 1.77642C8.85773 1.67299 8.92053 1.57901 8.99969 1.49985C9.07885 1.42069 9.17283 1.35789 9.27626 1.31505C9.37969 1.27221 9.49055 1.25016 9.6025 1.25016H10.3975C10.5095 1.25016 10.6203 1.27221 10.7237 1.31505C10.8272 1.35789 10.9211 1.42069 11.0003 1.49985C11.0795 1.57901 11.1423 1.67299 11.1851 1.77642C11.2279 1.87985 11.25 1.99071 11.25 2.10266V2.50016H13.75V3.75016H6.25V2.50016ZM18.3075 4.55766L19.1912 5.44141L11.875 12.7589L8.8075 9.69266L9.69125 8.80891L11.875 10.9914L18.3075 4.55766Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_17619_2403">
          <rect width="20" height="20" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
