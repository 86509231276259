export function Excel() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14.6857 11.5133L6.384 10.0508V20.8575C6.3841 20.9749 6.40732 21.0912 6.45234 21.1996C6.49736 21.308 6.56329 21.4065 6.64638 21.4895C6.72946 21.5724 6.82807 21.6382 6.93657 21.683C7.04508 21.7279 7.16135 21.7509 7.27875 21.7508H21.6037C21.7213 21.7511 21.8377 21.7282 21.9464 21.6834C22.0551 21.6387 22.1538 21.573 22.2371 21.49C22.3203 21.407 22.3864 21.3085 22.4315 21.1999C22.4766 21.0914 22.4999 20.9751 22.5 20.8575V16.8758L14.6857 11.5133Z"
        fill="#185C37"
      />
      <path
        d="M14.6857 2.25H7.27875C7.16135 2.2499 7.04508 2.27293 6.93657 2.31776C6.82807 2.3626 6.72946 2.42837 6.64638 2.51132C6.56329 2.59426 6.49736 2.69276 6.45234 2.80119C6.40732 2.90962 6.3841 3.02585 6.384 3.14325V7.125L14.6857 12L19.0815 13.4625L22.5 12V7.125L14.6857 2.25Z"
        fill="#21A366"
      />
      <path d="M6.384 7.125H14.6857V12H6.384V7.125Z" fill="#107C41" />
      <path
        opacity="0.1"
        d="M12.3255 6.14844H6.384V18.3359H12.3255C12.5622 18.3348 12.789 18.2403 12.9566 18.0731C13.1242 17.9059 13.2192 17.6794 13.221 17.4427V7.04169C13.2192 6.80494 13.1242 6.57843 12.9566 6.41123C12.789 6.24403 12.5622 6.14961 12.3255 6.14844Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.8372 6.63672H6.384V18.8242H11.8372C12.074 18.823 12.3007 18.7286 12.4684 18.5614C12.636 18.3942 12.731 18.1677 12.7327 17.931V7.52997C12.731 7.29322 12.636 7.06671 12.4684 6.89951C12.3007 6.73231 12.074 6.6379 11.8372 6.63672Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.8372 6.63672H6.384V17.8492H11.8372C12.074 17.848 12.3007 17.7536 12.4684 17.5864C12.636 17.4192 12.731 17.1927 12.7327 16.956V7.52997C12.731 7.29322 12.636 7.06671 12.4684 6.89951C12.3007 6.73231 12.074 6.6379 11.8372 6.63672Z"
        fill="black"
      />
      <path
        opacity="0.2"
        d="M11.349 6.63672H6.384V17.8492H11.349C11.5857 17.848 11.8125 17.7536 11.9801 17.5864C12.1477 17.4192 12.2427 17.1927 12.2445 16.956V7.52997C12.2427 7.29322 12.1477 7.06671 11.9801 6.89951C11.8125 6.73231 11.5857 6.6379 11.349 6.63672Z"
        fill="black"
      />
      <path
        d="M2.3955 6.63672H11.349C11.5862 6.63652 11.8137 6.73049 11.9816 6.89799C12.1496 7.06549 12.2441 7.2928 12.2445 7.52997V16.4685C12.2441 16.7056 12.1496 16.933 11.9816 17.1004C11.8137 17.2679 11.5862 17.3619 11.349 17.3617H2.3955C2.27804 17.3619 2.16168 17.339 2.05309 17.2942C1.9445 17.2494 1.84581 17.1836 1.76264 17.1007C1.67948 17.0177 1.61348 16.9192 1.56841 16.8107C1.52334 16.7022 1.5001 16.5859 1.5 16.4685V7.52997C1.5001 7.41251 1.52334 7.29621 1.56841 7.18773C1.61348 7.07926 1.67948 6.98073 1.76264 6.89777C1.84581 6.81481 1.9445 6.74906 2.05309 6.70427C2.16168 6.65948 2.27804 6.63652 2.3955 6.63672Z"
        fill="url(#paint0_linear_1374_13514)"
      />
      <path
        d="M4.275 14.9032L6.15825 11.9903L4.43325 9.09375H5.8185L6.75975 10.9485C6.84675 11.124 6.90975 11.2545 6.93825 11.3415H6.951C7.0125 11.2005 7.07775 11.0647 7.146 10.932L8.1525 9.09675H9.4275L7.65825 11.9767L9.4725 14.9055H8.11575L7.02825 12.8723C6.97776 12.7848 6.93485 12.6932 6.9 12.5985H6.882C6.85029 12.6908 6.80801 12.7791 6.756 12.8617L5.63625 14.9032H4.275Z"
        fill="white"
      />
      <path
        d="M21.6045 2.25H14.6857V7.125H22.5V3.14325C22.4999 3.02579 22.4767 2.90949 22.4316 2.80102C22.3865 2.69254 22.3205 2.59401 22.2374 2.51105C22.1542 2.4281 22.0555 2.36234 21.9469 2.31755C21.8383 2.27276 21.722 2.2498 21.6045 2.25Z"
        fill="#33C481"
      />
      <path d="M14.6857 12H22.5V16.875H14.6857V12Z" fill="#107C41" />
      <defs>
        <linearGradient
          id="paint0_linear_1374_13514"
          x1="3.3705"
          y1="5.93472"
          x2="10.374"
          y2="18.0637"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#18884F" />
          <stop offset="0.5" stop-color="#117E43" />
          <stop offset="1" stop-color="#0B6631" />
        </linearGradient>
      </defs>
    </svg>
  );
}
