interface SurveyCreateMenuProps {
  size?: number;
  color?: string;
}

export function SurveyCreateMenu({
  size = 24,
  color = '#fff',
}: SurveyCreateMenuProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 17 19"
      fill="none"
    >
      <path
        d="M16.9999 14.3996H14.4284V11.8281H13.1427V14.3996H10.5713V15.6853H13.1427V18.2567H14.4284V15.6853H16.9999V14.3996Z"
        fill={color}
      />
      <path
        d="M7.61422 18.2578L0.928502 14.2721C0.542787 14.015 0.285645 13.6292 0.285645 13.1792V5.33638C0.285645 4.88638 0.542787 4.43638 0.928502 4.24353L7.35707 0.45067C7.54993 0.322098 7.74279 0.257812 7.99993 0.257812C8.25707 0.257812 8.44993 0.322098 8.64279 0.45067L15.0714 4.24353C15.4571 4.50067 15.7142 4.88638 15.7142 5.33638V9.25781H14.4285V5.33638L7.99993 1.54353L1.57136 5.33638V13.1792L8.32136 17.165L7.61422 18.2578Z"
        fill={color}
      />
    </svg>
  );
}
