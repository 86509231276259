import { Box, css, styled } from '@ghq-abi/design-system';

const userBoxStyles = css({});

const arrowStyles = css({
  width: 0,
  height: 0,
  borderLeft: '6px solid transparent',
  borderRight: '6px solid transparent',
  borderTop: '6px solid $gray550',
});

const userContextStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: '$md',
  backgroundColor: 'transparent',
  textAlign: 'left',
  fontSize: '$md',
  position: 'relative',
  justifyContent: 'space-between',
  border: '1px solid $colors$gray410',
  borderRadius: '$default',
  px: '$3',
  py: '$sm',

  '@lg': {
    gap: '$lg',
    minWidth: 220,
  },
});

export const StyledUserBox = styled(Box, userBoxStyles);
export const StyledArrow = styled('span', arrowStyles);
export const StyledContext = styled('button', userContextStyles);
