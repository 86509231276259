import React from 'react';

export const QuestionDetails = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clip-path="url(#clip0_1007_5730)">
        <path d="M19.5 20H13.5V21.5H19.5V20Z" fill="#325A6D" />
        <path d="M22.5 17H13.5V18.5H22.5V17Z" fill="#325A6D" />
        <path d="M22.5 14H13.5V15.5H22.5V14Z" fill="#325A6D" />
        <path
          d="M15.4117 8.91275L12 2L8.58821 8.91275L0.959961 10.0212L6.47996 15.4025L5.17646 23L10.5 20.2017V18.5068L7.16921 20.258L7.95821 15.656L8.09171 14.8783L7.52696 14.3285L4.18346 11.0682L8.80421 10.397L9.58421 10.2838L9.93371 9.5765L12 5.38925L14.0662 9.5765L14.4157 10.2838L15.1957 10.397L20.7862 11.2108L21 9.725L15.4117 8.91275Z"
          fill="#325A6D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1007_5730">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
