import * as React from 'react';

type DownloadPdfProps = {
  size?: number;
  color?: string;
};

export function DownloadPdf({
  size = 24,
  color = 'inherit',
}: DownloadPdfProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      color={color}
      viewBox="0 0 24 24"
    >
      <g clip-path="url(#clip0_19420_10849)">
        <path
          d="M18 18V21H6V18H4.5V21C4.5 21.3978 4.65804 21.7794 4.93934 22.0607C5.22064 22.342 5.60218 22.5 6 22.5H18C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V18H18Z"
          fill="currentColor"
        />
        <path
          d="M15.75 15.75L14.6895 14.6895L12.75 16.629V10.5H11.25V16.629L9.3105 14.6895L8.25 15.75L12 19.5L15.75 15.75Z"
          fill="currentColor"
        />
        <path
          d="M21 3V1.5H16.5V9H18V6H20.25V4.5H18V3H21Z"
          fill="currentColor"
        />
        <path
          d="M12.75 9H9.75V1.5H12.75C13.3465 1.50066 13.9185 1.73792 14.3403 2.15973C14.7621 2.58155 14.9993 3.15346 15 3.75V6.75C14.9993 7.34654 14.7621 7.91845 14.3403 8.34027C13.9185 8.76208 13.3465 8.99934 12.75 9ZM11.25 7.5H12.75C12.9488 7.49978 13.1395 7.42069 13.2801 7.28009C13.4207 7.13948 13.4998 6.94885 13.5 6.75V3.75C13.4998 3.55115 13.4207 3.36052 13.2801 3.21991C13.1395 3.07931 12.9488 3.00022 12.75 3H11.25V7.5Z"
          fill="currentColor"
        />
        <path
          d="M6.75 1.5H3V9H4.5V6.75H6.75C7.14766 6.74946 7.52888 6.59126 7.81007 6.31007C8.09126 6.02888 8.24946 5.64766 8.25 5.25V3C8.24954 2.60232 8.09136 2.22105 7.81016 1.93984C7.52895 1.65864 7.14768 1.50046 6.75 1.5ZM4.5 5.25V3H6.75L6.75075 5.25H4.5Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_19420_10849">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
