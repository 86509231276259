import { useNav } from '~/app/contexts/NavContext';

import { NavContent, NavDrawer } from './components';
import { StyledWrapper } from './styles';

export function Nav() {
  const { isCollapsed, openCollapse, toggleCollapse } = useNav();

  return (
    <>
      <StyledWrapper>
        <NavContent
          isCollapsed={isCollapsed}
          onOpenRequest={openCollapse}
          onToggleRequest={toggleCollapse}
        />
      </StyledWrapper>

      <NavDrawer />
    </>
  );
}
