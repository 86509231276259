import React from 'react';

export const ManagementDetails = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1606_8074)">
        <path
          d="M8.00012 9.77953C8.37195 9.77953 8.73542 9.66927 9.04459 9.4627C9.35375 9.25612 9.59472 8.9625 9.73701 8.61898C9.8793 8.27545 9.91653 7.89745 9.84399 7.53276C9.77145 7.16808 9.5924 6.83309 9.32948 6.57017C9.06656 6.30725 8.73157 6.1282 8.36689 6.05566C8.0022 5.98312 7.6242 6.02035 7.28067 6.16264C6.93715 6.30493 6.64353 6.5459 6.43695 6.85506C6.23038 7.16422 6.12012 7.5277 6.12012 7.89953C6.12012 8.39814 6.31819 8.87632 6.67076 9.22889C7.02332 9.58146 7.50151 9.77953 8.00012 9.77953V9.77953ZM8.00012 6.73064C8.2313 6.73064 8.45729 6.7992 8.64952 6.92764C8.84174 7.05608 8.99156 7.23863 9.08003 7.45222C9.1685 7.6658 9.19165 7.90083 9.14655 8.12757C9.10144 8.35431 8.99012 8.56259 8.82665 8.72606C8.66317 8.88953 8.4549 9.00086 8.22816 9.04596C8.00141 9.09106 7.76639 9.06791 7.5528 8.97944C7.33922 8.89097 7.15666 8.74115 7.02822 8.54893C6.89978 8.35671 6.83123 8.13072 6.83123 7.89953C6.83123 7.58952 6.95438 7.29221 7.17359 7.073C7.3928 6.85379 7.69011 6.73064 8.00012 6.73064Z"
          fill="#325A6D"
        />
        <path
          d="M9.77772 1.77951C9.77772 1.54377 9.68407 1.31767 9.51737 1.15097C9.35067 0.984275 9.12458 0.890625 8.88883 0.890625H7.11106C6.87531 0.890625 6.64922 0.984275 6.48252 1.15097C6.31582 1.31767 6.22217 1.54377 6.22217 1.77951V4.89063H9.77772V1.77951ZM8.88883 4.00174H7.11106V1.77951H8.88883V4.00174Z"
          fill="#325A6D"
        />
        <path
          d="M11.5555 13.3349V12.3127C11.5568 12.1942 11.5158 12.0792 11.4399 11.9882C11.0086 11.5077 10.4768 11.128 9.88232 10.8761C9.28781 10.6242 8.64514 10.5062 7.99991 10.5305C7.35364 10.5078 6.71033 10.6279 6.11576 10.8822C5.52118 11.1364 4.98996 11.5186 4.55991 12.0016C4.48435 12.0927 4.44342 12.2076 4.44435 12.326V13.3349H5.15546V12.3882C5.52013 12.005 5.9627 11.7043 6.45337 11.5065C6.94403 11.3087 7.47138 11.2184 7.99991 11.2416C8.52876 11.2189 9.05632 11.31 9.547 11.5086C10.0377 11.7071 10.4801 12.0086 10.8444 12.3927V13.3349H11.5555Z"
          fill="#325A6D"
        />
        <path
          d="M12.4445 2.66797H10.6667V3.55686H12.4445V14.2235H3.55564V3.55686H5.33341V2.66797H3.55564C3.31989 2.66797 3.0938 2.76162 2.9271 2.92832C2.7604 3.09502 2.66675 3.32111 2.66675 3.55686V14.2235C2.66675 14.4593 2.7604 14.6854 2.9271 14.8521C3.0938 15.0188 3.31989 15.1124 3.55564 15.1124H12.4445C12.6803 15.1124 12.9064 15.0188 13.0731 14.8521C13.2398 14.6854 13.3334 14.4593 13.3334 14.2235V3.55686C13.3334 3.32111 13.2398 3.09502 13.0731 2.92832C12.9064 2.76162 12.6803 2.66797 12.4445 2.66797V2.66797Z"
          fill="#325A6D"
        />
      </g>
      <defs>
        <clipPath id="clip0_1606_8074">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
