import { components } from 'react-select';
import { Box, css, reusables, styled, theme } from '@ghq-abi/design-system';

export const multipleSelectStyles = css(reusables.boxSizing, {
  appearance: 'none',
  fontFamily: theme.fonts.satoshi,
  width: '$full',
  zIndex: '$auto',

  '.multiple-select__': {
    '&clearIndicator': {
      cursor: 'pointer',
      color: 'inherit',
      p: 0,

      '&:hover': {
        color: 'inherit',
      },
    },
    '&container': {},
    '&control': {
      py: theme.space[3],
      px: theme.space.md,
      border: `1px solid ${theme.colors.gray410}`,
      boxShadow: 'none',
      minHeight: 'unset',

      '&:hover': {
        border: `1px solid ${theme.colors.gray410}`,
      },

      '&:focus-within': {
        outline: `2px solid ${theme.colors.black} !important`,
      },
    },
    '&dropdownIndicator': {},
    '&group': {},
    '&groupHeading': {},
    '&indicatorsContainer': {
      color: theme.colors.gray550,
      gap: theme.space.sm,
    },
    '&indicatorSeparator': {
      display: 'none',
    },
    '&input': {},
    '&loadingIndicator': {},
    '&loadingMessage': {},
    '&menu': {
      boxShadow: 'none',
      border: `1px solid ${theme.colors.gray450}`,
      zIndex: '$dropdown',
      borderRadius: theme.radii.default,
    },
    '&menuList': {
      py: theme.space.xs,
      px: theme.space.sm,
    },
    '&menuPortal': {},

    '&multiValue': {
      background: theme.colors.white,
      p: 0,
      m: 0,
    },
    '&multiValueLabel': {
      color: theme.colors.gray750,
      fontSize: theme.fontSizes.sm,
      fontWeight: theme.fontWeights.medium,
      p: 0,
    },
    '&multiValueRemove': {
      p: 0,
      m: 0,
      cursor: 'pointer',
      display: 'none',
      '&:hover': {
        background: theme.colors.white,
      },
    },

    '&noOptionsMessage': {},
    '&option': {
      bg: theme.colors.white,
      color: theme.colors.gray750,
      my: theme.space.sm,
      p: theme.space.sm,
      fontSize: theme.fontSizes.sm,
      transition: 'background-color 100ms linear',
      cursor: 'pointer',

      '&.is-selected': {
        my: theme.space.sm,
        p: theme.space.sm,
        bg: theme.colors.gray200,
        borderRadius: theme.radii.default,
      },

      '&.is-focused': {
        bg: theme.colors.gray300,
        borderRadius: theme.radii.default,
      },

      '&.is-focused:focus-visible': {
        outline: `2px solid ${theme.colors.black}`,
      },
    },
    '&placeholder': {
      color: theme.colors.gray550,
      fontWeight: theme.fontWeights.medium,
      m: 0,
    },
    '&singleValue': {},
    '&valueContainer': {
      m: 0,
      p: 0,
      overflowY: 'auto',
      fontSize: theme.fontSizes.sm,

      '.multiple-select__multiValue:not(:last-of-type)': {
        mr: theme.space.xs,
        '&::after': {
          content: ',',
        },
      },
    },
  },

  variants: {
    isClearableItems: {
      true: {
        '.multiple-select__multiValueRemove': {
          display: 'flex',
        },
      },
    },

    disabled: {
      true: {
        opacity: 0.6,
        cursor: 'not-allowed',

        '.multiple-select__control': {
          bg: theme.colors.white,
        },
      },
    },
  },
});

export const multipleSelectOptionStyles = css(reusables.boxSizing, {
  display: 'flex',
  variants: {
    active: {
      true: {
        backgroundColor: theme.colors.gray300,
      },
    },
  },
});

export const checkboxStyles = css(reusables.boxSizing, {
  all: 'unset',
  userSelect: 'none',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  alignItems: 'center',
  display: 'inline-flex',
  justifyContent: 'center',
  lineHeight: theme.lineHeights.none,

  color: theme.colors.white,
  boxShadow: `inset 0 0 0 2px ${theme.colors.gray525}`,
  overflow: 'hidden',
  borderRadius: theme.radii.default,
  fontSize: theme.sizes[6],
  transition: theme.transitions['quickly-linear'],

  '&:focus': {
    boxShadow: `inset 0 0 0 1px ${theme.colors.gray525}, 0 0 0 2px ${theme.colors.gray525}`,
  },

  '&:focus:not(:focus-visible)': {
    boxShadow: `inset 0 0 0 2px ${theme.colors.gray525}`,
  },

  '&[data-state="checked"]': {
    backgroundColor: theme.colors.blue350,
    boxShadow: `inset 0 0 0 2px ${theme.colors.blue350}`,

    '&:focus': {
      boxShadow: `inset 0 0 0 1px ${theme.colors.blue350}, 0 0 0 2px ${theme.colors.blue350}`,
    },

    '&:focus:not(:focus-visible)': {
      boxShadow: `inset 0 0 0 2px ${theme.colors.blue350}`,
    },
  },
  '&:checked': {
    backgroundColor: theme.colors.blue350,
    boxShadow: `inset 0 0 0 2px ${theme.colors.blue350}`,

    '&:focus': {
      boxShadow: `inset 0 0 0 1px ${theme.colors.blue350}, 0 0 0 2px ${theme.colors.blue350}`,
    },

    '&:focus:not(:focus-visible)': {
      boxShadow: `inset 0 0 0 2px ${theme.colors.blue350}`,
    },
  },

  '&[data-disabled]': {
    cursor: 'not-allowed',
    opacity: 0.6,
  },
  '&:disabled': {
    cursor: 'not-allowed',
    opacity: 0.6,
  },

  variants: {
    size: {
      sm: {
        width: theme.sizes[3],
        height: theme.sizes[3],
      },
      md: {
        width: theme.sizes[4],
        height: theme.sizes[4],
      },
      lg: {
        width: theme.sizes[6],
        height: theme.sizes[6],
      },
    },
  },
});

export const StyledCheckbox = styled('input', checkboxStyles);
export const StyledMultipleSelect = styled(Box, multipleSelectStyles);

export const StyledSelectOption = styled(
  components.Option,
  multipleSelectOptionStyles
);

export const checkStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '$white',

  height: '100%',
  width: '100%',
  svg: {
    position: 'absolute',
    display: 'none',
    color: 'white',
    pointerEvents: 'none',
  },
  variants: {
    active: {
      true: {
        svg: {
          display: 'block',
        },
      },
    },
  },
});

export const StyledCheck = styled('span', checkStyles);
