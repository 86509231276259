import { useRouter } from 'next/router';
import { Box, Flex, Text } from '@ghq-abi/design-system';
import { useTranslate } from '@tolgee/react';

import { ErrorBoundary, Header, Nav } from '~/app/components';
import { useFullscreen } from '~/app/contexts/FullscreenContext';
import { useNav } from '~/app/contexts/NavContext';
import { useExpireSession } from '~/shared/auth/useExpireSession';
import { useLogout } from '~/shared/auth/useLogout';

import { StyledContent, StyledWrapper } from './styles';

type AppTemplateProps = {
  children: React.ReactNode;
  hasUserNonce: boolean;
};

const SERVICE_NOW_LINKS = {
  DEFAULT:
    'https://abinbevww.service-now.com/abiex?id=sc_cat_item&sys_id=5ad2235adb1cc050f17eb29f29961934',
  DASHBOARD:
    'https://abinbevww.service-now.com/abiex?id=sc_cat_item&sys_id=5ad2235adb1cc050f17eb29f29961934&sysparm_variables=%7B%22business_service%22%20:%20%22c96dc15edbd4a7402f1b51a4ce96190e%22%7D',
} as const;

export function AppTemplate({ children, hasUserNonce }: AppTemplateProps) {
  useLogout();
  useExpireSession();

  const router = useRouter();

  const { isCollapsed } = useNav();
  const fullscreen = useFullscreen();

  const { t } = useTranslate(['default']);

  const isOnPersonalDashboard = router.route === '/dashboard/me';

  if (!hasUserNonce) {
    return null;
  }

  return (
    <>
      {fullscreen.deactivated && <Header />}

      <StyledWrapper fullscreen={fullscreen.activated}>
        {fullscreen.deactivated && <Nav />}

        <StyledContent
          as="main"
          navCollapsed={isCollapsed}
          fullscreen={fullscreen.activated}
        >
          <ErrorBoundary inApp>
            <Box css={{ flex: 1 }}>{children}</Box>
            {fullscreen.deactivated ? (
              <Flex as="footer" justify="center" css={{ mt: '$lg' }}>
                <Text
                  css={{
                    fontWeight: '$medium',
                    fontSize: '$xs',
                    color: '$black',
                    textAlign: 'center',
                  }}
                >
                  {t('footer.have_any_questions')}
                  <Text
                    as="a"
                    href={
                      isOnPersonalDashboard
                        ? SERVICE_NOW_LINKS.DASHBOARD
                        : SERVICE_NOW_LINKS.DEFAULT
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    css={{
                      fontSize: 'inherit',
                      color: '#0053ad',
                      textDecoration: 'underline',
                    }}
                  >
                    {' '}
                    Service Now
                  </Text>
                </Text>
              </Flex>
            ) : null}
          </ErrorBoundary>
        </StyledContent>
      </StyledWrapper>
    </>
  );
}
