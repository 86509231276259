import { styled } from '@ghq-abi/design-system';

export const StyledWrapper = styled('div', {
  zIndex: '$modal',
  backgroundColor: '$white',
  transform: 'translateX(-100%)',
  height: '100%',
  transition: '$promptly-ease',
  visibility: 'hidden',

  display: 'flex',
  flexDirection: 'column',

  position: 'fixed',
  top: 0,
  left: 0,

  '@motion': {
    transition: 'none',
  },

  variants: {
    open: {
      true: {
        transform: 'translateX(0)',
        visibility: 'visible',
      },
    },
  },
});
