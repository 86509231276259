import { useSession } from 'next-auth/react';

export function useUser() {
  const { data: session } = useSession();

  return {
    name: session?.user.proxiedAs?.name ?? session?.user.name,
    zone: session?.user.proxiedAs?.zone ?? session?.user.zone,
    globalId: session?.user.proxiedAs?.globalId ?? session?.user.globalId,
    employeeId: session?.user.proxiedAs?.employeeId ?? session?.user.employeeId,
    roles: session?.user.proxiedAs?.roles ?? session?.user.roles,
    managedZones:
      session?.user.proxiedAs?.managedZones ?? session?.user.managedZones,
  };
}

export type User = ReturnType<typeof useUser>;
