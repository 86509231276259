import React from 'react';

export const SkipComment = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clip-path="url(#clip0_1007_5740)">
        <path
          d="M3.75001 21.5001C3.55109 21.5001 3.36033 21.421 3.21968 21.2804C3.07902 21.1397 3.00001 20.949 3.00001 20.7501V4.25005C2.99948 4.11789 3.03389 3.98794 3.09974 3.87336C3.16559 3.75878 3.26055 3.66363 3.37501 3.59755C3.48902 3.53173 3.61835 3.49707 3.75001 3.49707C3.88166 3.49707 4.01099 3.53173 4.12501 3.59755L18.375 11.8476C18.4904 11.913 18.5864 12.0078 18.6532 12.1225C18.72 12.2371 18.7552 12.3674 18.7552 12.5001C18.7552 12.6327 18.72 12.763 18.6532 12.8776C18.5864 12.9923 18.4904 13.0871 18.375 13.1526L4.12501 21.4026C4.0107 21.4673 3.88139 21.501 3.75001 21.5001ZM4.50001 5.54755V19.4526L16.5 12.5001L4.50001 5.54755Z"
          fill="#325A6D"
        />
        <path d="M22.5 3.5H21V21.5H22.5V3.5Z" fill="#325A6D" />
      </g>
      <defs>
        <clipPath id="clip0_1007_5740">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
