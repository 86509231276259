export type OPRRates =
  | 'Underperformer'
  | 'Movers'
  | 'Excelling'
  | 'Newcomer'
  | 'Not Rated';

export function getOPRRateColor(rate: OPRRates) {
  switch (rate) {
    case 'Underperformer':
      return '#797979';
    case 'Movers':
      return '#a67a2f';
    case 'Excelling':
      return '#1b3c50';
    case 'Newcomer':
      return '#5c9ac3';
    case 'Not Rated':
      return '#bcbcbc';
    default:
      return '';
  }
}
