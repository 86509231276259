import * as React from 'react';
import { FaChevronDown } from 'react-icons/fa';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Box, CSS } from '@ghq-abi/design-system';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import {
  StyledAccordion,
  StyledContent,
  StyledHeader,
  StyledItem,
  StyledLink,
  StyledTrigger,
} from './styles';

type AccordionPrimitiveProps = React.ComponentProps<
  typeof AccordionPrimitive.Root
>;
type AccordionProps = AccordionPrimitiveProps & { css?: CSS };

export const NavAccordion = React.forwardRef<
  React.ElementRef<typeof StyledAccordion>,
  AccordionProps
>(({ children, ...props }, forwardedRef) => (
  <StyledAccordion
    ref={forwardedRef}
    {...props}
    {...(props.type === 'single' ? { collapsible: true } : {})}
  >
    {children}
  </StyledAccordion>
));

NavAccordion.displayName = 'NavAccordion';

type AccordionTriggerPrimitiveProps = React.ComponentProps<
  typeof AccordionPrimitive.Trigger
>;
type AccordionTriggerProps = AccordionTriggerPrimitiveProps & {
  css?: CSS;
  active?: boolean;
  collapsed?: boolean;
};

const NavAccordionTrigger = React.forwardRef<
  React.ElementRef<typeof StyledTrigger>,
  AccordionTriggerProps
>(({ children, collapsed, ...props }, forwardedRef) => (
  <StyledHeader>
    <StyledTrigger {...props} collapsed={collapsed} ref={forwardedRef}>
      {children}
      {!collapsed && <FaChevronDown className="chevron-icon" />}
    </StyledTrigger>
  </StyledHeader>
));

NavAccordionTrigger.displayName = 'NavAccordionTrigger';

type AccordionContentPrimitiveProps = React.ComponentProps<
  typeof AccordionPrimitive.Content
>;
type AccordionContentProps = AccordionContentPrimitiveProps & { css?: CSS };

const NavAccordionContent = React.forwardRef<
  React.ElementRef<typeof StyledContent>,
  AccordionContentProps
>(({ children, ...props }, forwardedRef) => (
  <StyledContent {...props} ref={forwardedRef}>
    <Box css={{ mt: 'calc($md + $px + $px)' }} />
    {children}
    <Box css={{ mb: 'calc($md + $px + $px)' }} />
  </StyledContent>
));

NavAccordionContent.displayName = 'NavAccordionContent';

type NavAccordionLinkProps = {
  children: React.ReactNode;
  href: string;
} & React.ComponentProps<typeof StyledLink>;

function NavAccordionLink({
  children,
  href,
  active,
  ...props
}: NavAccordionLinkProps) {
  return (
    <Link href={href} passHref legacyBehavior>
      <StyledLink {...props} active={active}>
        {children}
      </StyledLink>
    </Link>
  );
}

type AccordionItemPrimitiveProps = React.ComponentProps<
  typeof AccordionPrimitive.Item
>;
type AccordionItemProps = Omit<AccordionItemPrimitiveProps, 'children'> & {
  css?: CSS;
  triggerIcon: React.ReactNode;
  triggerTitle: string;
  links: Array<{ href: string; title: string; show?: boolean }>;
  collapsed?: boolean;
};

export const NavAccordionItem = React.forwardRef<
  React.ElementRef<typeof StyledItem>,
  AccordionItemProps
>(({ triggerIcon, triggerTitle, links, collapsed, ...props }, forwardedRef) => {
  const { route } = useRouter();

  const isAnyLinkActive = links.some(link => route === link.href);
  const linksToShow = links.filter(link => link.show !== false);

  return (
    <StyledItem {...props} ref={forwardedRef}>
      <NavAccordionTrigger
        active={isAnyLinkActive}
        collapsed={collapsed}
        title={collapsed ? triggerTitle : undefined}
      >
        {triggerIcon}
        {!collapsed && triggerTitle}
      </NavAccordionTrigger>

      {!collapsed && (
        <NavAccordionContent>
          {linksToShow.map(link => (
            <NavAccordionLink
              key={`${link.href}-${link.title}`}
              href={link.href}
              active={route === link.href}
            >
              {link.title}
            </NavAccordionLink>
          ))}
        </NavAccordionContent>
      )}
    </StyledItem>
  );
});

NavAccordionItem.displayName = 'NavAccordionItem';
