import { RiQuestionLine } from 'react-icons/ri';
import { CSS, IconButton, styled, theme } from '@ghq-abi/design-system';

const StyledIcon = styled(RiQuestionLine, {
  padding: 0,
  color: theme.colors.gray750,
});

const StyledIconButton = styled(IconButton, {
  margin: 0,
  padding: '3px',
  pb: '0',
  px: '4px',
  backgroundColor: 'transparent',
  display: 'inline-block',
  position: 'absolute',
  border: 'none',
  marginLeft: theme.space[1],
  borderRadius: theme.radii.circle,

  '&:hover': {
    backgroundColor: theme.colors.gray410,
    [`${StyledIcon}`]: {
      color: theme.colors.black,
    },
  },
});

type FormHelperButtonProps = Omit<
  React.ComponentProps<typeof StyledIconButton>,
  'icon'
> & {
  iconCss?: CSS;
};

export function FormHelperButton({ iconCss, ...props }: FormHelperButtonProps) {
  return <StyledIconButton type="button" icon={<StyledIcon />} {...props} />;
}
