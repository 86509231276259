import { keyframes, styled } from '@ghq-abi/design-system';
import * as AccordionPrimitive from '@radix-ui/react-accordion';

import { linkStyles } from '../NavLink/styles';

const slideDown = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-accordion-content-height)' },
});

const slideUp = keyframes({
  from: { height: 'var(--radix-accordion-content-height)' },
  to: { height: 0 },
});

export const StyledAccordion = styled(AccordionPrimitive.Root, {
  display: 'flex',
  flexDirection: 'column',
  gap: 6,
});

export const StyledItem = styled(AccordionPrimitive.Item, {});

export const StyledHeader = styled(AccordionPrimitive.Header, {
  all: 'unset',
});

export const StyledTrigger = styled(AccordionPrimitive.Trigger, linkStyles, {
  backgroundColor: 'transparent',
  textAlign: 'left',
  appearance: 'none',
  width: '100%',
  border: 0,
  cursor: 'pointer',

  '.chevron-icon': {
    transition: 'transform 175ms cubic-bezier(0.65, 0, 0.35, 1)',
    ml: 'auto',
  },

  '&[data-state="open"]': {
    '.chevron-icon': {
      transform: 'rotate(180deg)',
    },
  },
});

export const StyledContent = styled(AccordionPrimitive.Content, {
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  pl: '$20',
  pr: '$2',

  '&[data-state="open"]': {
    animation: `${slideDown} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },

  '&[data-state="closed"]': {
    animation: `${slideUp} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
});

export const StyledLink = styled('a', {
  fontSize: '$sm',
  fontWeight: '$bold',
  lineHeight: '$5',
  maxWidth: 'max-content',
  color: '$gray750',
  textDecoration: 'none',
  position: 'relative',
  transition: '$quickly-linear',

  '&:hover, &:focus': {
    textDecoration: 'underline',
  },

  '& + &': {
    mt: '$7',
  },

  variants: {
    active: {
      true: {
        fontWeight: 900,
        textDecoration: 'underline',
      },
    },
  },
});
