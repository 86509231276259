import * as React from 'react';
import { useRouter } from 'next/router';

import { Overlay } from '~/shared/components';

type NavContextData = {
  isCollapsed: boolean;
  isAsideOpen: boolean;
  toggleCollapse: () => void;
  openCollapse: () => void;
  closeAside: () => void;
};

const NavContext = React.createContext({} as NavContextData);

type NavProviderProps = {
  children: React.ReactNode;
  initialIsCollapsed?: boolean;
};

const LIMIT_WIDTH = 1300;

const isUnderLimitWidth = () => window.innerWidth <= LIMIT_WIDTH;

export function NavProvider({
  children,
  initialIsCollapsed = false,
}: NavProviderProps) {
  const router = useRouter();

  const [isCollapsed, setIsCollapsed] = React.useState(initialIsCollapsed);
  const [isAsideOpen, setIsAsideOpen] = React.useState(false);

  React.useEffect(() => {
    function handleWindowResize() {
      if (isUnderLimitWidth()) {
        setIsCollapsed(true);
      } else {
        setIsAsideOpen(false);
      }
    }

    if (isUnderLimitWidth()) {
      setIsCollapsed(true);
    } else {
      setIsAsideOpen(false);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const closeAside = React.useCallback(() => {
    setIsAsideOpen(false);
  }, []);

  React.useEffect(() => {
    function handleRouteChange() {
      closeAside();
    }

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [closeAside, router.events]);

  function toggleCollapse() {
    if (isUnderLimitWidth()) {
      setIsAsideOpen(state => !state);
    } else {
      setIsCollapsed(state => !state);
    }
  }

  function openCollapse() {
    if (isUnderLimitWidth()) {
      setIsAsideOpen(true);
    } else {
      setIsCollapsed(false);
    }
  }

  const providerValue = React.useMemo(
    () => ({
      isAsideOpen,
      isCollapsed,
      toggleCollapse,
      openCollapse,
      closeAside,
    }),
    [closeAside, isAsideOpen, isCollapsed]
  );

  return (
    <NavContext.Provider value={providerValue}>
      {children}
      {isAsideOpen && <Overlay aria-hidden onClick={closeAside} />}
    </NavContext.Provider>
  );
}

export function useNav() {
  const context = React.useContext(NavContext);

  if (!context) {
    throw new Error('useNav must be used within a NavProvider');
  }

  return context;
}
